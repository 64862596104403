exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-tsx": () => import("./../../../src/pages/acerca.tsx" /* webpackChunkName: "component---src-pages-acerca-tsx" */),
  "component---src-pages-cambios-tsx": () => import("./../../../src/pages/cambios.tsx" /* webpackChunkName: "component---src-pages-cambios-tsx" */),
  "component---src-pages-charts-tsx": () => import("./../../../src/pages/charts.tsx" /* webpackChunkName: "component---src-pages-charts-tsx" */),
  "component---src-pages-colonias-tsx": () => import("./../../../src/pages/colonias.tsx" /* webpackChunkName: "component---src-pages-colonias-tsx" */),
  "component---src-pages-comparar-colonias-tsx": () => import("./../../../src/pages/comparar-colonias.tsx" /* webpackChunkName: "component---src-pages-comparar-colonias-tsx" */),
  "component---src-pages-crimen-tsx": () => import("./../../../src/pages/crimen.tsx" /* webpackChunkName: "component---src-pages-crimen-tsx" */),
  "component---src-pages-cuadrantes-mapa-tsx": () => import("./../../../src/pages/cuadrantes-mapa.tsx" /* webpackChunkName: "component---src-pages-cuadrantes-mapa-tsx" */),
  "component---src-pages-dia-tsx": () => import("./../../../src/pages/dia.tsx" /* webpackChunkName: "component---src-pages-dia-tsx" */),
  "component---src-pages-hora-tsx": () => import("./../../../src/pages/hora.tsx" /* webpackChunkName: "component---src-pages-hora-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mapa-tsx": () => import("./../../../src/pages/mapa.tsx" /* webpackChunkName: "component---src-pages-mapa-tsx" */),
  "component---src-pages-numero-tsx": () => import("./../../../src/pages/numero.tsx" /* webpackChunkName: "component---src-pages-numero-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-rumbo-mapa-tsx": () => import("./../../../src/pages/rumbo-mapa.tsx" /* webpackChunkName: "component---src-pages-rumbo-mapa-tsx" */),
  "component---src-pages-sectores-mapa-tsx": () => import("./../../../src/pages/sectores-mapa.tsx" /* webpackChunkName: "component---src-pages-sectores-mapa-tsx" */),
  "component---src-pages-tasas-tsx": () => import("./../../../src/pages/tasas.tsx" /* webpackChunkName: "component---src-pages-tasas-tsx" */),
  "component---src-pages-template-tsx": () => import("./../../../src/pages/template.tsx" /* webpackChunkName: "component---src-pages-template-tsx" */),
  "component---src-pages-tendencias-tsx": () => import("./../../../src/pages/tendencias.tsx" /* webpackChunkName: "component---src-pages-tendencias-tsx" */)
}

